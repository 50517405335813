const reasonList = () => {
  return [
    {
      group: 'N1',
      name: 'Cadastro',
      detail: [
        { name: 'Alteração cadastral' },
        { name: 'Dados de Nota Fiscal' },
        { name: 'Dados do cliente' }
      ]
    },
    {
      group: 'N1',
      name: 'Cashback',
      detail: [{ name: 'Prazo de liberação' }]
    },
    {
      group: 'N1',
      name: 'Compras (Pré-Venda)',
      detail: [
        { name: 'Cotação' },
        { name: 'Disponibilidades' },
        { name: 'Troca inteligente' },
        { name: 'Locação/Allugator' },
        { name: 'Dúvidas sobre produtos ACER' }
      ]
    },
    {
      group: 'N1',
      name: 'Entrega',
      detail: [
        { name: 'Prazo de entrega' },
        { name: 'Rastreio' },
        { name: 'Pagamento GNRE' },
        { name: 'Reembolso de taxa GNRE' }
      ]
    },
    {
      group: 'N1',
      name: 'Garantia',
      detail: [
        { name: 'Adesão e garantia estendida' },
        { name: 'Cancelamento de garantia estendida' },
        { name: 'Garantia Acer certificado' },
        { name: 'Garantia Acer validade' },
        { name: 'Nota fiscal garantia estendida' }
      ]
    },
    {
      group: 'N1',
      name: 'Nota Fiscal',
      detail: [{ name: 'PDF' }, { name: 'XML' }]
    },
    {
      group: 'N1',
      name: 'Pagamentos',
      detail: [
        { name: 'Pagamento de PIX' },
        { name: 'Pagamento aprovado (Pedido)' },
        { name: 'Prazos de pagamento' }
      ]
    },
    {
      group: 'N1',
      name: 'Pedidos',
      detail: [
        { name: 'Cancelamento Cielo' },
        { name: 'Cancelamento Bradesco' },
        { name: 'Cancelamento Mercado Pago' },
        { name: 'Confirmação de Pedido' },
        { name: 'Redirecionamento Marketplace' }
      ]
    },
    {
      group: 'N1',
      name: 'Suporte',
      detail: [
        { name: 'Solicitação de peças' },
        { name: 'Solicitação de reparo' },
        { name: 'Suporte técnico' },
        { name: 'Upgrade' }
      ]
    },
    {
      group: 'N1',
      name: 'Outros N1',
      detail: [{ name: 'Texto livre' }]
    },
    {
      group: 'N2',
      name: 'Pedidos',
      detail: [
        { name: 'Acer Club' },
        { name: 'Bloqueio de estoque' },
        { name: 'Cancelamento Marketplace' },
        { name: 'Erro de integração' },
        { name: 'Pedido pendente' },
        { name: 'Solicitação de Brindes' }
      ]
    },
    {
      group: 'N2',
      name: 'Entrega',
      detail: [
        { name: 'Comprovante de entrega' },
        { name: 'Prazo de entrega transportadora' },
        { name: 'Reembolso de frete' },
        { name: 'Atraso na entrega' }
      ]
    },
    {
      group: 'N2',
      name: 'Nota fiscal',
      detail: [{ name: 'Carta de correção' }]
    },
    {
      group: 'N2',
      name: 'Outros N2',
      detail: [{ name: 'Texto livre' }]
    },
    {
      group: 'N3',
      name: 'Cashback',
      detail: [{ name: 'Liberação de valores' }]
    },
    {
      group: 'N3',
      name: 'Entrega',
      detail: [{ name: 'Abertura de PI' }]
    },
    {
      group: 'N3',
      name: 'Nota fiscal',
      detail: [{ name: 'Divergência de valor' }]
    },
    {
      group: 'N3',
      name: 'Pedidos',
      detail: [{ name: 'Cancelamento marketplace' }]
    },
    {
      group: 'N3',
      name: 'Cancelamento',
      detail: [
        { name: 'Arrependimento' },
        { name: 'Avaria no transporte' },
        { name: 'Bloqueio de estoque' },
        { name: 'Cancelamento indevido/Estorno pelo marketplace' },
        { name: 'Endereço incorreto' },
        { name: 'Erro de integração' },
        { name: 'Extravio' },
        { name: 'Extravio mercado envios' },
        { name: 'Fraude' },
        { name: 'Cadastro divergente' },
        { name: 'Insucesso na entrega' },
        { name: 'Vício de fábrica' }
      ]
    },
    {
      group: 'N3',
      name: 'Devolução',
      detail: [
        { name: 'Devolução agilizada mercado livre' },
        { name: 'Devolução agilizada full' },
        { name: 'Devolução por duplicidade' },
        { name: 'Modelo incorreto' }
      ]
    },
    {
      group: 'N3',
      name: 'Reenvio',
      detail: [
        { name: 'Novo pedido(Reenvio)' },
        { name: 'Novo pedido(Insucesso na entrega)' }
      ]
    },
    {
      group: 'N3',
      name: 'Troca',
      detail: [
        { name: 'Avaria(Aguardando NF/Rastreio)' },
        { name: 'Vício após o recebimento(Reincidência)' },
        { name: 'Vício de até 7 dias' }
      ]
    },
    {
      group: 'N3',
      name: 'Outros N3',
      detail: [{ name: 'Texto livre' }]
    },
    {
      group: 'TODOS',
      name: 'N1',
      detail: []
    },
    {
      group: 'TODOS',
      name: 'N2',
      detail: []
    },
    {
      group: 'TODOS',
      name: 'Sem motivos',
      detail: []
    }
  ];
};

export default reasonList;
